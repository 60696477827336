import React from 'react';
import '../Resources/matrix.css';
import MatrixRain from "../Components/Matrix/MatrixRain";

function Home() {
  return(
    <MatrixRain />
  );
}

export default Home;
