/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import RainStream from "./RainStream";

const MatrixRain = props => {
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState(null); // ?{width, height}

    useEffect(() => {
        const boundingClientRect = containerRef.current.getBoundingClientRect();
        setContainerSize({
            width: boundingClientRect.width,
            height: boundingClientRect.height,
        });
    }, []);

    const streamCount = containerSize ? Math.floor(containerSize.width / 26) : 0;

    return (
        <>
        <div
            style={{
                background: 'black',
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                overflow: 'ignore',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
            }}
            ref={containerRef}>
            {new Array(streamCount).fill().map(_ => (
                <RainStream height={containerSize?.height} />
            ))}
        </div>
        <div style={{
            margin: 0,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        }}>
            <p style={{
                fontFamily: "arial",
                fontSize: `${!containerSize || Math.floor(containerSize.width / 1000) === 0 ? 1 : Math.floor(containerSize.width / 1000)}em`,
                color: "white",
                textAlign: "center",
            }}>Follow the white rabbit</p>
        </div>
        </>
    );
};

export default MatrixRain;